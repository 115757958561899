import classes from "./LoadingPage.module.scss";
import {useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../redux/store";

export const LoadingPage = () => {
   const  {progress, isLoaded} = useSelector((state:RootState)=>state.loadingStatusSlice)

    const loadingAreaRef = useRef<HTMLDivElement>(null)
    const shadowRef = useRef<HTMLDivElement>(null)
    const imageAreaRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if(isLoaded) {
            if(loadingAreaRef.current) loadingAreaRef.current.className = `${classes.fadeoutAnimation} ${classes.loadingArea}`
            if(shadowRef.current) shadowRef.current.className = `${classes.fadeoutAnimation} ${classes.shadow}`
            if(imageAreaRef.current) imageAreaRef.current.className = `${classes.fadeoutAnimation} ${classes.imageArea}`
            setTimeout(() => {
                if(loadingAreaRef.current) loadingAreaRef.current.hidden = true
                if(shadowRef.current) shadowRef.current.hidden = true
                if(imageAreaRef.current) imageAreaRef.current.hidden = true
            },2000)
        }
    }, [isLoaded]);

    const makeItLessThan100 = (value:number) => {
        let result = 100
        if (value <= 100) result = value
        return result
    }

    return (
        <>
            <div ref={loadingAreaRef} className={classes.loadingArea}></div>
            <div ref={shadowRef} className={classes.shadow}></div>
            <div className={classes.imageArea}  ref={imageAreaRef}>
                <div>
                    <img src={"avatar_c_square_512.png"} alt="avatar"/>
                    <p>loading... {makeItLessThan100(Math.floor(progress))} %</p>
                </div>
                <p>良好なインターネット通信環境でのご利用を推奨します。</p>
            </div>
        </>
    )
}


