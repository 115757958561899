import {Canvas, extend, useFrame, useLoader, useThree} from "@react-three/fiber"
import {OrbitControls} from "@react-three/drei"
import classes from "./GltfCanvas.module.scss";
import React, {useEffect, useRef} from "react";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {VrmAvatar} from "../VrmAvatar";
import {Vector3} from "three";
import {FBXLoader} from "three/examples/jsm/loaders/FBXLoader";
import * as THREE from 'three'
import {Quaternion} from 'three';
import {isSmartPhone} from "../functions";

extend({OrbitControls});

const Ie = () => {
    const gltf = useLoader(GLTFLoader, '/environmentModels/house2.glb');

    useEffect(() => {
        gltf.scene.scale.x = 1
        gltf.scene.scale.y = 1
        gltf.scene.scale.z = 1
        gltf.scene.position.x = -5
        const degree = 180
        gltf.scene.rotateY(degree * (Math.PI / 180))
    }, [])
    return <primitive object={gltf.scene}/>;
};


const Ghost = () => {
    const gltf = useLoader(FBXLoader, '/environmentModels/halloween_ghost.fbx');
    const textureLoader = new THREE.TextureLoader();
    const texture = textureLoader.load('/environmentModels/textures/Ghost_BaseColor.png');
    const textureMap = new THREE.MeshStandardMaterial({map: texture});

    if (gltf) {
        gltf.traverse(child => {
            if (child instanceof THREE.Mesh) {
                child.material = textureMap;
            }
        });
    }

    gltf.scale.y = 0.002
    gltf.scale.x = 0.002
    gltf.scale.z = 0.002
    gltf.position.x = isSmartPhone() ? 0.9 : -1.2
    gltf.position.y = isSmartPhone() ? 0.2 : 0.3
    gltf.position.z = isSmartPhone() ? 0.3 : 1
    gltf.rotateY(90 * (Math.PI / 360))
    gltf.rotateX(20 * (Math.PI / 360))

    return <primitive object={gltf}/>;
};

const CatWithLight = () => {
    const gltf = useLoader(FBXLoader, '/environmentModels/cat_with_lights.fbx');

    const textureLoader = new THREE.TextureLoader();
    const texture = textureLoader.load('/environmentModels/textures/cat/cat_with_lights.png');
    const lightTexture = textureLoader.load('/environmentModels/textures/cat/lights_color.png');
    const textureMap = new THREE.MeshStandardMaterial({map: texture});
    const lightTextureMap = new THREE.MeshStandardMaterial({map: lightTexture});

    if (gltf) {
        gltf.traverse(child => {
            if (child instanceof THREE.Mesh) {
                if (child.name === 'lights') {
                    child.material = lightTextureMap;
                } else {
                    child.material = textureMap;
                }
            }
        });
    }
    const yRotation = new Quaternion();
    yRotation.setFromAxisAngle(new Vector3(0, 1, 0), Math.PI);
    const xRotation = new Quaternion();
    xRotation.setFromAxisAngle(new Vector3(-1, 0, 0), Math.PI / 2);
    // 2つのクォータニオンを合成
    const combinedRotation = yRotation.multiply(xRotation);
    // クォータニオンをモデルのquaternionに設定
    gltf.quaternion.copy(combinedRotation);

    gltf.position.x = isSmartPhone() ? 0.7 : 1
    gltf.position.y = 0.3
    gltf.position.z = 0
    gltf.scale.y = 0.2
    gltf.scale.x = 0.2
    gltf.scale.z = 0.2
    return <primitive object={gltf}/>;
};


const XYZGridHelper = ({size = 10, divisions = 10, xColor = 'red', yColor = 'green', zColor = 'blue'}) => {
    return (
        <>
            <gridHelper args={[size, divisions, xColor, zColor]} rotation={[0, 0, Math.PI / 2]}/>
            <gridHelper args={[size, divisions, yColor, zColor]} rotation={[0, Math.PI / 2, 0]}/>
            <gridHelper args={[size, divisions, zColor, xColor]} rotation={[Math.PI / 2, 0, 0]}/>
        </>
    );
}

const CameraControls = () => {
    const {camera} = useThree();
    const controlsRef = useRef();

    useFrame(() => {
        camera.lookAt(0, 1.7, 0); // カメラの注視点を固定

        // カメラの位置を固定する処理をここに追加する
        // 例: camera.position.x = -1.5;
        // 例: camera.position.y = 1.7;
        // 例: camera.position.z = -8;
    });

    return null; // レンダリングされる要素はないため、null を返す
};

export const GltfCanvas = () => {
    // const { camera, gl } = useThree();

    return (
        <>
            {/*{!isLoaded && <LoadingPage progress={progress}/>}*/}
            <div className={classes.gltfCanvasArea}
            >
                <Canvas
                    className={classes.gltfCanvas}
                    frameloop="always"
                    camera={{fov: 25, near: 0.1, far: 10000, position: [-1.5, 1.7, -8]}}//カメラの位置
                    flat={true}
                >
                    <ambientLight intensity={0.3} position={[4, 2, -5]} color="white"/>
                    <directionalLight intensity={0.9} position={[4, 2, -5]} color={"0xFFFFFF"}/>
                    <Ie/>
                    <Ghost/>
                    <CatWithLight/>
                    <VrmAvatar/>
                    <color attach="background" args={["#f7f7f7"]}/>
                    {/*<OrbitControls*/}
                    {/*    enableZoom={true}*/}
                    {/*    enablePan={true}*/}
                    {/*    enableDamping={true}*/}
                    {/*    target={[0, 1.7, 0.0]}//カメラがみる位置*/}
                    {/*/>*/}
                    <CameraControls/>
                    {/*<XYZGridHelper/>*/}
                </Canvas>
            </div>
        </>
    )
}
