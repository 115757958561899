import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export const loadingStatusSlice = createSlice({
    name: "loadingStatus",
    initialState: {
        progress: 0,
        isLoaded:false,
    },
    reducers: {
        setProgress: (state, action: PayloadAction<number>)=> {
            state.progress = action.payload
        },
        setIsLoaded: (state, action:PayloadAction<boolean>)=> {
            state.isLoaded = action.payload
        },
    },
});

export const { setProgress,setIsLoaded } = loadingStatusSlice.actions