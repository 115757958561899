import classes from "./TermsOfUseModal.module.scss";
import React from "react";
import closeButton from "../assets/close.svg";

type Props = {
    setIsTermsOfUseModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const TermsOfUseModal = (props:Props) => {
    return(
        <div className={classes.modalArea}>
            <div className={classes.modal} onClick={() => props.setIsTermsOfUseModal(false)}></div>
            <div className={classes.contentArea}>
                <img className={classes.closeButton} src={closeButton} alt="closeButton"
                     onClick={() => props.setIsTermsOfUseModal(false)}/>
                <div className={classes.suiteInfoTitle}>▼サイトの引用について</div>
                <div className={classes.suiteInfoContent}>
                    当サイトはリンクフリーです。リンクを行う場合の許可や連絡は不要です。引用する際は当サイトURLの明記もしくはリンクをお願いします。
                </div>
                <div className={classes.copyrightsTitle}>▼著作権・肖像権について</div>
                <div className={classes.copyrightsContent}>
                    当サイトで掲載しているすべてのコンテンツ（文章、画像、動画、音声、ファイル等）の著作権・肖像権等は当サイト所有者または各権利所有者が保有し、許可なく無断利用（転載、複製、譲渡、二次利用等）することを禁止します。また、コンテンツの内容を変形・変更・加筆修正することも一切認めておりません。
                    各権利所有者におかれましては万一掲載内容に問題がございましたら、ご本人様よりお問い合わせください。迅速に対応いたします。
                </div>
                <div className={classes.disclaimerTitle}>▼免責事項について</div>
                <div className={classes.disclaimerContent}>
                    当サイトの利用によって生じた損害等の一切の責任を負いかねますのでご了承ください。
                    当サイトからリンクやバナーなどによって他のサイトに移動した場合、移動先サイトで提供される情報、サービス等について一切の責任を負いません。
                </div>

                <div className={classes.contentFooter}>
                    ※本利用規約は、事前の予告なく変更することがあります。
                    本利用規約の変更は、当サイトに掲載された時点で有効になるものとします。
                </div>
            </div>
        </div>
    )
 }