import React from 'react';
import classes from './App.module.scss';
import {GltfCanvas} from './components/GltfCanvas';
import {BurgerMenu} from "./components/BurgerMenu";
import {LoadingPage} from "./components/LoadingPage";
import {CanvasUi} from "./components/CanvasUi";
import {Header} from "./components/Header";

function App() {

    return (
        <>
            <div id={"appPage"} className={classes.appPage}>
                <LoadingPage />
                <BurgerMenu pageWrapId="threeFiberArea" outerContainerId="appPage" />
                <div id={"threeFiberArea"} className={classes.threeFiberArea}>
                    <GltfCanvas/>
                </div>
                <Header />
                <CanvasUi/>
            </div>
        </>
    );
}

export default App;
