import React from "react";
import {stack as Menu} from "react-burger-menu";
import classes from "./BurgerMenu.module.scss";
import {useSettingsStore} from "../settingsStore";
import shallow from "zustand/shallow";
import {useVrmStore} from "../vrmStore";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../redux/store";
import {setRole} from "../redux/roleSlice";
import TwitterLogo from "../assets/x-logo-black.png";
import {ReactComponent as TeamFerrets} from "../assets/teamferrets_logo.svg";
import {isSmartPhone} from "../functions";

type Props = {
    pageWrapId: string
    outerContainerId: string
}

export const BurgerMenu = (props: Props) => {
    const dispatch = useDispatch()
    const {role} = useSelector((state: RootState) => state.roleSlice)

    const styles = {
        bmBurgerButton: {
            position: 'absolute',
            width: '36px',
            height: '30px',
            right: '18px',
            top: '18px'
        },
        bmBurgerBars: {
            backgroundColor: '#ffffff',
            border: "1px solid darkgreen"
        },
        bmBurgerBarsHover: {},
        bmCrossButton: {
            height: '24px',
            width: '24px'
        },
        bmCross: {
            background: '#bdc3c7'
        },
        bmMenuWrap: {
            position: 'fixed',
            height: '100%'
        },
        bmMenu: {
            background: '#373a47',
            padding: '20px 20px 0 20px',
            overflow: 'hidden',
        },
        bmMorphShape: {
            fill: '#373a47'
        },
        bmItemList: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
        },
        bmItem: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        bmOverlay: {
            background: 'rgba(0, 0, 0, 0.3)'
        }
    }

    const smartPhoneStyles = {
        bmBurgerButton: {
            position: 'absolute',
            width: '36px',
            height: '30px',
            right: '18px',
            top: '18px'
        },
        bmBurgerBars: {
            backgroundColor: '#ffffff',
            border: "1px solid darkgreen"
        },
        bmBurgerBarsHover: {},
        bmCrossButton: {
            height: '24px',
            width: '24px'
        },
        bmCross: {
            background: '#bdc3c7'
        },
        bmMenuWrap: {
            position: 'fixed',
            height: '100%',
            width: '160px',
        },
        bmMenu: {
            background: '#373a47',
            padding: '10px',
            overflow: 'hidden',
        },
        bmMorphShape: {
            fill: '#373a47'
        },
        bmItemList: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            gap: '20px',
        },
        bmItem: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        bmOverlay: {
            background: 'rgba(0, 0, 0, 0.3)'
        }
    }

    const {mode, setMode} = useSettingsStore((state) => ({
        mode: state.mode,
        setMode: state.setMode
    }), shallow,)

    const {model, setModel} = useVrmStore((state) => ({
        model: state.model,
        setModel: state.setModel
    }), shallow,)

    const logoClickEvent = () => {
        window.open(twitterText(), '_blank')
    }

    const twitterText = () => {
        const text = "Virtual Friends AIを活用した仮想アバターとコミュニケーションが出来る、新しい体験を提供するアプリです。AIの進化を体感してみませんか？%0A"
        const tag = "TeamFerrets,VirtualFriend"
        const url = "https://virtual-friend.net/%0A";

        if (text.length > 140) {
            alert("テキストが140字を超えています");
        } else {
            return `http://twitter.com/intent/tweet?url=${url}&hashtags=${tag}&text=${text}`
        }
    }
    return (
        <Menu pageWrapId={props.pageWrapId} outerContainerId={props.outerContainerId} itemListElement="div" right
              styles={isSmartPhone() ? smartPhoneStyles : styles} className={classes.menuArea}>
            <div>

                <div className={classes.avatarArea}>
                    <h2>アバター設定</h2>
                    <div>
                        <button disabled={model === "boy"}
                                onClick={() => setModel("boy")}>男性にする
                        </button>
                        <button disabled={model === "girl"}
                                onClick={() => setModel("girl")}>女性にする
                        </button>
                    </div>
                </div>

                <div className={classes.roleArea}>
                    <h2>キャラの性格</h2>
                    <div>
                        <button disabled={role === "default"}
                                onClick={() => dispatch(setRole("default"))}>一般人
                        </button>
                        <button disabled={role === "kansai"}
                                onClick={() => dispatch(setRole("kansai"))}>関西人
                        </button>
                        <button disabled={role === "maid"}
                                onClick={() => dispatch(setRole("maid"))}>秋葉原メイド
                        </button>
                        <button disabled={role === "tundere"}
                                onClick={() => dispatch(setRole("tundere"))}>ツンデレ
                        </button>
                        <button disabled={role === "yakuza"}
                                onClick={() => dispatch(setRole("yakuza"))}>怖い人
                        </button>
                        <button disabled={role === "tyuni"}
                                onClick={() => dispatch(setRole("tyuni"))}>厨二病
                        </button>
                        <button disabled={role === "oldMan"}
                                onClick={() => dispatch(setRole("oldMan"))}>ご老人
                        </button>
                    </div>
                </div>
            </div>
            <div className={classes.logoArea}>
                <button onClick={logoClickEvent}>
                    <img src={TwitterLogo} alt="Xでポストする"></img>
                    <span>ポスト</span>
                </button>
                <div onClick={() => window.open("https://team-ferrets.com", "_blank")}>
                    <p className={classes.logoText}>created by</p>
                    <TeamFerrets/>
                </div>
            </div>
        </Menu>
    );
};

