import classes from "./PrivacyPolicyModal.module.scss";
import React from "react";
import closeButton from "../assets/close.svg"

type Props = {
    setIsPrivacyPolicyModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const PrivacyPolicyModal = (props: Props) => {
    return (
        <div className={classes.modalArea}>
            <div className={classes.modal} onClick={() => props.setIsPrivacyPolicyModal(false)}></div>
            <div className={classes.contentArea}>
                <img className={classes.closeButton} src={closeButton} alt="closeButton"
                     onClick={() => props.setIsPrivacyPolicyModal(false)}/>
                <h1 className={classes.advertisementTitle}>▼広告の配信について</h1>
                <p className={classes.advertisementContent}>
                    当サイトはGoogle及びGoogleのパートナー（第三者配信事業者）の提供する広告を設置しております。その広告配信にはCookieを使用し、当サイトやその他のサイトへの過去のアクセス情報に基づいて広告を配信します。
                    <br/>
                    Google が広告 Cookie を使用することにより、当サイトや他のサイトにアクセスした際の情報に基づいて、Google
                    やそのパートナーが適切な広告を表示しています。
                    <br/>
                    当サイト利用者様は
                    <a href="https://adssettings.google.com/u/0/authenticated">Googleアカウントの広告設定ページ</a>
                    で、パーソナライズ広告を無効にできます。また
                    <a href="https://www.aboutads.info/choices/">https://www.aboutads.info/choices/</a>
                    のページにアクセスして頂き、パーソナライズ広告掲載に使用される第三者配信事業者のCookieを無効にできます。
                    <br/>
                    その他、Googleの広告におけるCookieの取り扱い詳細については、
                    <a href="https://policies.google.com/technologies/ads">Googleのポリシーと規約ページ</a>
                    をご覧ください。
                </p>
                <h1 className={classes.adsenseTitle}>▼当サイトが使用しているアクセス解析ツールについて</h1>
                <p className={classes.adsenseContent}>
                    当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。
                    このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。
                    このトラフィックデータは匿名で収集されており、個人を特定するものではありません。
                    この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。
                    この規約に関して、詳しくは
                    <a href="http://www.google.com/analytics/terms/jp.html">Google アナリティクス利用規約</a>
                    、または
                    <a href="https://www.google.com/intl/ja/policies/privacy/partners/">Googleのポリシーと規約ページ</a>
                    をクリックしてください。
                </p>
                <h1 className={classes.inquiriesTitle}>▼個人情報について</h1>
                <p className={classes.inquiriesContent}>
                    当サイトは、以下を含む正当な理由がある場合を除き、個人情報を第三者に提供することはありません。
                    <br/>・ご本人の同意がある場合
                    <br/>・法令に基づく場合
                    <br/>・人の生命・身体・財産の保護に必要な場合
                    <br/>・公衆衛生・児童の健全育成に必要な場合
                    <br/>・国の機関等の法令の定める事務への協力の場合（税務調査、統計調査等）
                </p>
                <p className={classes.contentFooter}>
                    ※本プライバシーポリシーは、事前の予告なく変更することがあります。
                    本プライバシーポリシーの変更は、当サイトに掲載された時点で有効になるものとします。
                </p>
            </div>
        </div>
    )
}