import classes from "./CanvasUi.module.scss";
import React, {useEffect, useRef, useState} from "react";
import {useSettingsStore} from "../settingsStore";
import shallow from "zustand/shallow";
import SpeechRecognition, {useSpeechRecognition} from "react-speech-recognition";
import axios from "axios";
import {useSelector} from "react-redux";
import {RootState} from "../redux/store";

export const CanvasUi = () => {
    const  { role} = useSelector((state:RootState)=>state.roleSlice)

    const {mode, setMode} = useSettingsStore((state) => ({
        mode: state.mode,
        setMode: state.setMode
    }), shallow,)

    const {
        transcript,
        finalTranscript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition,
    } = useSpeechRecognition();

    const [chatApiResText, setChatApiResText] = useState<string>("")
    const [thinkingText, setThinkingText] = useState("回答を考え中です")
    const speechSynthesis = useRef<SpeechSynthesisUtterance>(new SpeechSynthesisUtterance())
    const talkingButtonRef = useRef<HTMLButtonElement>(null)
    const stopButtonRef = useRef<HTMLButtonElement>(null)
    const thinkingIdRef = useRef<NodeJS.Timeout | null>(null)

    useEffect(() => {
        if (finalTranscript !== "") startAnimation()
    }, [finalTranscript])

    const thinkingCountUp = () => {
        thinkingIdRef.current = setTimeout(() => {
            if (!stopButtonRef.current!!.disabled) {
                setThinkingText((prevState) => {
                    if (prevState === "回答を考え中です...") return "回答を考え中です"
                    return prevState + "."
                })
                thinkingCountUp()
            }
        }, 1000)
    }

    const startAnimation = async () => {
        setMode("thinking")
        thinkingCountUp()
        const requestData = {
            text:finalTranscript,
            role:role
        }
        const chatGptRes = await axios.post(process.env.REACT_APP_FUNCTION_API_URL_FOR_CHATGPT ?? '',requestData)
        const chatGptText = chatGptRes.data
        if (talkingButtonRef.current) {
            if (!talkingButtonRef.current.disabled) return
        }

        if (thinkingIdRef.current) clearTimeout(thinkingIdRef.current)
        speechSynthesis.current.text = chatGptText
        speechSynthesis.current.rate = 0.95
        window.speechSynthesis.speak(speechSynthesis.current)
        setChatApiResText(chatGptText)
        setMode("talking")
        speechSynthesis.current.onend = () => {
            setThinkingText("回答を考え中です")
            setMode("idle")
            if (talkingButtonRef.current) talkingButtonRef.current.disabled = false
        }
    }

    const talkingButtonEvent = () => {
        if (stopButtonRef.current) stopButtonRef.current.disabled = false
        if (talkingButtonRef.current) talkingButtonRef.current.disabled = true
        setMode("listen")
        setChatApiResText("")
        SpeechRecognition.startListening()
    }

    const stopButtonEvent = () => {
        if (stopButtonRef.current) stopButtonRef.current.disabled = true
        window.speechSynthesis.cancel()
        resetTranscript()
        setChatApiResText("")
        setThinkingText("回答を考え中です")
        if (thinkingIdRef.current) clearTimeout(thinkingIdRef.current)
        SpeechRecognition.stopListening()
        setMode("idle")
        if (talkingButtonRef.current) talkingButtonRef.current.disabled = false
    }

    const generateVrmStatus = () => {
        if (mode === 'idle') {
            return <p>右下のボタンを押して<br/>気になることを聞いてみてください</p>
        } else if (mode === 'error') {
            return <p>エラーが発生しました<br/>もう一度話してください</p>
        } else if (mode === 'listen') {
            return <p>マイクに向かって話してください</p>
        } else if (mode === 'thinkingGpt') {
            return <p>回答を考え中です</p>
        } else if (mode === 'thinking') {
            return <p>{thinkingText}</p>
        } else if (mode === 'talking') {
            return <p>喋っています</p>
        } else if (mode === 'initial') {
            return <p>右下のボタンを押して<br/>気になることを聞いてみてください</p>
        }
    }

    return (
        <div>
            <div className={classes.vrmStatusArea}>
                {generateVrmStatus()}
            </div>
            <div className={classes.footerArea}>
                <div className={classes.textArea}>
                    {transcript && <p>あなた:{transcript}</p>}
                    <p>{chatApiResText && `回答:${chatApiResText}`}</p>
                </div>
                <div className={classes.buttonArea}>
                    <button
                        ref={talkingButtonRef}
                        onClick={talkingButtonEvent}>押して話す
                    </button>
                    <button ref={stopButtonRef}
                            onClick={stopButtonEvent}>停止
                    </button>
                </div>
            </div>

            {/*{isModal &&*/}
            {/*    <Authenticate setIsModal={setIsModal}/>*/}
            {/*}*/}
        </div>
    )
}