/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/no-unknown-property */
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader'
import * as THREE_VRM from '@pixiv/three-vrm'
import * as THREE from 'three'
import React, {FC, useEffect, useState} from 'react'
import {loadMixamoAnimation} from './loadMixamoAnimation'
import shallow from 'zustand/shallow'
import {useVrmStore} from './vrmStore'
import {useThree} from '@react-three/fiber'
import {useSettingsStore} from './settingsStore'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "./redux/store";
import {setIsLoaded, setProgress} from "./redux/loadingStatusSlice";

type ModelToUrl = {
    AliciaSolid: string
    girl: string
    boy: string
}
const modelToUrl = {
    AliciaSolid: '/vrmModels/AliciaSolid.vrm',
    girl: '/vrmModels/girl.vrm',
    boy: '/vrmModels/boy.vrm',
} as ModelToUrl

export const AnimationNames = [
    'Idle',
    'Thinking',
    'Talking',
] as const

const states = [
    'Idle',
    'Thinking',
    'Talking',
]

const emotes = [
    'Idle',
    'Thinking',
    'Talking',
]

export const VrmAvatar = () => {
    const dispatch = useDispatch()
    const  { isLoaded} = useSelector((state:RootState)=>state.loadingStatusSlice)

    const {gl, scene, camera} = useThree()
    const {
        animation,
        model,
        inputVrmModel,
        emote,
        emoteFinish,
        setAnimation,
    } = useVrmStore(
        (state) => ({
            animation: state.animation,
            model: state.model,
            inputVrmModel: state.inputVrmModel,
            emote: state.emote,
            emoteFinish: state.emoteFinish,
            setAnimation: state.setAnimation,
        }),
        shallow,
    )
    const {mode} = useSettingsStore(
        (state) => ({
            mode: state.mode,
        }),
        shallow,
    )

    const setExpression = (name: string, weight: number) => {
        expression = {name, weight}
        vrm?.expressionManager?.setValue(expression.name, expression.weight)
    }
    const clearExpression = () => {
        if (expression) {
            vrm?.expressionManager?.setValue(expression.name, 0)
            expression = undefined
        }
    }

    /* ---------------------------------- 初期設定 ---------------------------------- */
    useEffect(() => {
        dispatch(setIsLoaded(false))
        gl.outputEncoding = THREE.sRGBEncoding

        const loader = new GLTFLoader()
        loader.crossOrigin = 'anonymous'
        loader.register((parser) => {
            return new THREE_VRM.VRMLoaderPlugin(parser, {
                autoUpdateHumanBones: true,
            })
        })
        let url = ''
        if (inputVrmModel) {
            const blob = new Blob([inputVrmModel], {
                type: 'application/octet-stream',
            })
            url = URL.createObjectURL(blob)
        }
        loader.load(
            url ? url : modelToUrl[model as keyof ModelToUrl],
            (gltf) => {
                if (vrm) {
                    scene.remove(vrm.scene)
                    THREE_VRM.VRMUtils.deepDispose(vrm.scene)
                }
                THREE_VRM.VRMUtils.removeUnnecessaryVertices(gltf.scene)
                THREE_VRM.VRMUtils.removeUnnecessaryJoints(gltf.scene)

                vrm = gltf.userData.vrm as THREE_VRM.VRM

                mixer = new THREE.AnimationMixer(gltf.userData.vrm.scene)
                const currentVRM = gltf.userData.vrm

                // currentVRM.scene.position.setY(-0.8)
                THREE_VRM.VRMUtils.rotateVRM0(vrm)

                currentVRM.scene.traverse((obj: any) => {
                    if (obj.isMesh) {
                        obj.castShadow = true
                        obj.receiveShadow = true
                    }
                    obj.frustumCulled = false
                })
                AnimationNames.forEach((name) => {
                    loadMixamoAnimation(name, `/animations/${name}.fbx`, currentVRM).then(
                        (clip) => {
                            if (mixer) {
                                const action = mixer.clipAction(clip)
                                const name = clip.name
                                actions[name] = action
                                if (emotes.indexOf(name) >= 0) {
                                    action.clampWhenFinished = true
                                    action.loop = THREE.LoopRepeat
                                }
                                if (name === animation) fadeToAction(animation, 0.5)
                            }
                        },
                    )
                })
                scene.add(vrm.scene)
                setTimeout(()=>{
                    dispatch(setIsLoaded(true))

                },1000)
            },
            (xhr) => {
                dispatch(setProgress((xhr.loaded / xhr.total) * 100))

            },
        )

        blinkAnimate()

    }, [model, inputVrmModel])

    const [timerId, setTimerId] = useState<NodeJS.Timer | undefined>(undefined)

    useEffect(() => {
        if (mode === "talking") {
            setTimerId(setInterval(() => {
                const mouthList = ['aa', 'ee', 'ih', 'oh', 'ou']
                const randomIndex = Math.floor(Math.random() * 5)
                if (vrm) {
                    vrm.expressionManager?.setValue(mouthList[randomIndex], 0)
                    const test = mouthValue() / 4
                    vrm.expressionManager?.setValue(mouthList[randomIndex], test)
                }

            }, 10))
        } else {
            if (timerId) clearInterval(timerId)
            if (vrm) {
                vrm.expressionManager?.setValue("aa", 0)
                vrm.expressionManager?.setValue("ih", 0)
                vrm.expressionManager?.setValue("oh", 0)
                vrm.expressionManager?.setValue("ou", 0)
                vrm.expressionManager?.setValue("ee", 0)
            }
        }
    }, [mode])

    /* ----------------------------- 基本的なアニメーションの処理 ----------------------------- */
    useEffect(() => {
        if (vrm) {
            const degree = 15
            const rotateX = (degree * (Math.PI / 180))
            vrm.scene.rotation.set(0, rotateX, 0)
            // THREE_VRM.VRMUtils.rotateVRM0(vrm)
            vrm.scene.position.setX(-0.9)
            vrm.scene.position.setY(0.3)
            vrm.scene.position.setZ(-5)
            vrm.lookAt!.target = camera
            if (mode === 'idle') {
                setAnimation('Idle')
            } else if (mode === 'error') {
                setAnimation('Idle')
            } else if (mode === 'thinking') {
                setAnimation('Thinking')
            } else if (mode === 'talking') {
                setAnimation('Talking')
            }
        }
    }, [mode, isLoaded])

    useEffect(() => {
        api.state = animation
        if (states.includes(animation) && !emote) {
            fadeToAction(api.state, 0.5)
        }
    }, [animation, emote])

    /* --------------------------------- エモートの処理 -------------------------------- */
    const restoreState = () => {
        mixer?.removeEventListener('finished', restoreState)
        emoteFinish()
        clearExpression()
    }

    useEffect(() => {
        clearExpression()
        if (emote === 'StandingGreeting') {
            setExpression('happy', 1)
        }
        if (emote) {
            fadeToAction(emote, 0.2)
            mixer?.addEventListener('finished', restoreState)
        }
    }, [emote])

    return (<></>)
}

/* -------------------------------- vrmの挙動の制御 ------------------------------- */
// https://threejs.org/examples/#webgl_animation_skinning_morph
let vrm: THREE_VRM.VRM | undefined = undefined
let mixer: THREE.AnimationMixer | undefined = undefined
let expression: { name: string; weight: number } | undefined = undefined
const actions: any = {}
let activeAction: any, previousAction: any
const clock = new THREE.Clock()
const api: any = {state: 'idle'}

function fadeToAction(name: string, duration: number) {
    previousAction = activeAction
    activeAction = actions[name]

    if (previousAction !== activeAction) {
        previousAction?.fadeOut(duration)
    }

    activeAction
        ?.reset()
        .setEffectiveTimeScale(1)
        .setEffectiveWeight(1)
        .fadeIn(duration)
        .play()
}

const blinkValue = () => {
    return Math.max(
        0,
        Math.sin((clock.elapsedTime * 1) / 3) ** 4096 +
        Math.sin((clock.elapsedTime * 4) / 7) ** 4096,
    )
}

const mouthValue = () => {
    const randomNum = Math.random() //0~1の数字
    const randomNum2 = randomNum / 12 //randomNumの割る数字を大きくするとスピードが速くなる、上のrandomNumと一緒の数字にしないとスピードがめちゃ速くなる
    const randomNum3 = 1 //この数字を大きくするとスピードが速くなる
    return Math.max(
        0,
        Math.sin((clock.elapsedTime * randomNum) / randomNum2) ** randomNum3 +
        Math.sin((clock.elapsedTime * randomNum) / randomNum2) ** randomNum3,
    )
}
const blinkAnimate = () => {
    const dt = clock.getDelta()

    requestAnimationFrame(blinkAnimate)
    if (mixer) mixer.update(dt)
    if (vrm) {
        vrm.expressionManager?.setValue('blink', blinkValue())
        vrm.update(dt)
    }
}


// eslint-disable-next-line @typescript-eslint/no-unused-vars
// function createGUI() {
//   const gui = new GUI()
//
//   // states
//   const statesFolder = gui.addFolder('States')
//   const clipCtrl = statesFolder.add(api, 'state').options(states)
//   clipCtrl.onChange(function () {
//     fadeToAction(api.state, 0.5)
//   })
//   statesFolder.open()
//
//   // emotes
//   const emoteFolder = gui.addFolder('Emotes')
//   function createEmoteCallback(name: string) {
//     api[name] = function () {
//       fadeToAction(name, 0.2)
//       mixer?.addEventListener('finished', restoreState)
//     }
//     emoteFolder.add(api, name)
//   }
//   function restoreState() {
//     mixer?.removeEventListener('finished', restoreState)
//     fadeToAction(api.state, 0.2)
//   }
//   for (let i = 0; i < emotes.length; i++) {
//     createEmoteCallback(emotes[i])
//   }
//   emoteFolder.open()
// }
