import classes from "./Header.module.scss";
import React, {useState} from "react";
import {PrivacyPolicyModal} from "./PrivacyPolicyModal";
import {TermsOfUseModal} from "./TermsOfUseModal";

export const Header = () => {
    const [isPrivacyPolicyModal, setIsPrivacyPolicyModal] = useState(false)
    const [isTermsOfUseModal, setIsTermsOfUseModal] = useState(false)

    return (
        <>
            <div className={classes.headerArea}>
                <h1>Virtual Friend</h1>
                <p className={classes.chatgpt}>Powered by GPT-3.5</p>
                <div className={classes.infoArea}>
                    <p onClick={() => setIsPrivacyPolicyModal(true)}>プライバシーポリシー</p>
                    <p onClick={() => setIsTermsOfUseModal(true)}>利用規約</p>
                    <p onClick={() => window.open("https://team-ferrets.com/contact?reference=virtual-friend", '_blank')}>お問い合わせ</p>
                </div>
                {/*<div className={classes.headerButtonArea}>*/}
                {/*    <button className={classes.loginButton}>login</button>*/}
                {/*    <button className={classes.signUpButton} onClick={()=>setIsModal(true)}>sign up</button>*/}
                {/*</div>*/}
            </div>
            {isPrivacyPolicyModal &&
                <PrivacyPolicyModal setIsPrivacyPolicyModal={setIsPrivacyPolicyModal}/>
            }
            {isTermsOfUseModal &&
                <TermsOfUseModal setIsTermsOfUseModal={setIsTermsOfUseModal}/>
            }
        </>
    )
}

