import { configureStore } from '@reduxjs/toolkit';
import {loadingStatusSlice} from "./loadingStatusSlice";
import {roleSlice} from "./roleSlice";

export const store = configureStore({
    reducer: {
        loadingStatusSlice:loadingStatusSlice.reducer,
        roleSlice:roleSlice.reducer
    },
});

export type RootState = ReturnType<typeof store.getState>
