import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export const roleSlice = createSlice({
    name: "roleSlice",
    initialState: {
        role: 'default'
    },
    reducers: {
        setRole: (state, action: PayloadAction<string>)=> {
            state.role = action.payload
        }
    },
});

export const {setRole } = roleSlice.actions